import React from 'react';
import {
  Table,
  TableColumn,
} from '@backstage/core-components';

import { VpnRequest } from '../../types';
import dayjs from 'dayjs';
interface ListRequestProps {
  requests: VpnRequest[];
}

type DenseTableProps = {
  requestHistoryDatas: VpnRequest[];
};

export const DenseTable = ({ requestHistoryDatas }: DenseTableProps) => {
  const columns: TableColumn[] = [
    {
      title: 'No.',
      field: 'number',
    },
    {
      title: 'Certificate Name',
      field: 'cert_name',
    },
    {
      title: 'Action',
      field: 'action',
    },
    {
      title: 'Requested Time',
      field: 'requested_at',
    },
    {
      title: 'Updated Time',
      field: 'updated_at',
    },
    {
      title: 'Status',
      field: 'status',
      highlight: true,
      cellStyle: (_, rowData: any) => {
        return rowData.status === 'APPROVED'
          ? {
              color: '#6CD75F',
            }
          : {
              color: '#DC3D5A',
            };
      },
    },
    {
      title: 'Description',
      field: 'description',
    },
  ];

  const data = requestHistoryDatas.map((h, i) => {
    return {
      number: i + 1,
      cert_name: h.cert_name != "" ? h.cert_name : "UNKNOWN",
      action: h.action,
      requested_at: dayjs(h.requested_at).format('DD/MM/YY HH:mm:ss A'),
      updated_at: dayjs(h.updated_at).format("DD/MM/YY HH:mm:ss A"),
      status: h.status,
      description: h.description,
    };
  });

  return (
    <Table
      title="Request History"
      options={{ search: true, paging: true }}
      columns={columns}
      data={data}
    />
  );
};

export const VpnRequestHistoryComponent: React.FC<ListRequestProps> = ({ requests }) => {

  return <DenseTable requestHistoryDatas={requests || []} />;
};
