import { configApiRef, createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { serviceDeskApiRef, ServiceDeskAPI } from './api';


export const servicedeskPlugin = createPlugin({
  id: 'servicedesk',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: serviceDeskApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, configApi, identityApi }) => {
        return new ServiceDeskAPI({
          discoveryApi,
          configApi,
          identityApi,
        });
      },
    }),
  ],
});

export const ServicedeskPage = servicedeskPlugin.provide(
  createRoutableExtension({
    name: 'ServicedeskPage',
    component: () =>
      import('./components/ServiceDeskComponent').then(m => m.ServiceDeskComponent),
    mountPoint: rootRouteRef,
  }),
);
