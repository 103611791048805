/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createPermission } from '@backstage/plugin-permission-common';

/**
 * @public
 */
export const RELEASE_LIST_RESOURCE_TYPE = 'release-list';

const releaseListCreate = createPermission({
  name: 'release.list.create',
  attributes: { action: 'create' },
});

const releaseListRead = createPermission({
  name: 'release.list.read',
  attributes: { action: 'read' },
  resourceType: RELEASE_LIST_RESOURCE_TYPE,
});

const releaseListUpdate = createPermission({
  name: 'release.list.update',
  attributes: { action: 'update' },
  resourceType: RELEASE_LIST_RESOURCE_TYPE,
});

const releaseListDelete = createPermission({
  name: 'release.list.delete',
  attributes: { action: 'delete' },
  resourceType: RELEASE_LIST_RESOURCE_TYPE,
});

const releaseFollowersUpdate = createPermission({
  name: 'release.followers.update',
  attributes: { action: 'update' },
  resourceType: RELEASE_LIST_RESOURCE_TYPE,
});

/**
 * @public
 */
export const permissions = {
  releaseListCreate,
  releaseListRead,
  releaseListUpdate,
  releaseListDelete,
  releaseFollowersUpdate,
};
