/*
 * Copyright 2021 Larder Software Limited
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

import fetch from 'cross-fetch';
import { Certificate, GritDocument, VpnRequest } from '../types';

export const serviceDeskApiRef = createApiRef<ServiceDeskAPI>({
  id: 'plugin.servicedesk.service',
});

const DEFAULT_PROXY_PATH = '/servicedesk';
const DONE_STATUS_CATEGORY = 'Done';

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

export class ServiceDeskAPI {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;
  private readonly confluenceActivityFilter: string | undefined;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;

    const proxyPath = options.configApi.getOptionalString('jira.proxyPath');
    this.proxyPath = proxyPath ?? DEFAULT_PROXY_PATH;

    this.identityApi = options.identityApi;
  }

  private async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    console.log("Proxy: " + proxyUrl);
    return {
      // apiUrl: `${proxyUrl}${this.proxyPath}/rest/api/`,
      baseUrl: `${proxyUrl}${this.proxyPath}`,
      vpnApiUrl: `${proxyUrl}${this.proxyPath}/vpn-requests`,
      certApiUrl: `${proxyUrl}${this.proxyPath}/certs`,
      docApiUrl: `${proxyUrl}${this.proxyPath}/documents`
    };
  }

  async getCetificateByUser({
    email,
  }: {
    email: string | undefined;
  }): Promise<Certificate[]> {
    const certApiUrl = (await this.getUrls()).certApiUrl;

    const request = await fetch(`${certApiUrl}/user?email=${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = await request.json();
    return response;
  }

  async getVpnRequestsByUser({
    email,
  }: {
    email: string | undefined;
  }): Promise<VpnRequest[]> {
    const vpnApiUrl = (await this.getUrls()).vpnApiUrl;

    const request = await fetch(`${vpnApiUrl}/user?email=${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = await request.json();
    return response;
  }

  async createVpnRequest({
    action,
    email,
    cert_id,
  }: {
    action: string | undefined;
    email: string | undefined;
    cert_id: string | undefined | null;
  }): Promise<VpnRequest> {
    const vpnApiUrl = (await this.getUrls()).vpnApiUrl;

    const data = {
      email: email,
      cert_id: cert_id,
    };

    const request = await fetch(`${vpnApiUrl}?action=${action}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = await request.json();
    return response;
  }

  async getAllDocument(): Promise<GritDocument[]> {
    const docApiUrl = (await this.getUrls()).docApiUrl;

    const request = await fetch(`${docApiUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = await request.json();
    return response;
  }

}
