import React, { useEffect, useState } from 'react';
import {
  Header,
  Page,
  Content,
  ContentHeader,
  SupportButton,
  LinkButton,
  ItemCardHeader,
  ItemCardGrid
} from '@backstage/core-components';

import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Breadcrumbs,
} from '@material-ui/core';
import { CommonHeaderComponent } from '../CommonHeaderComponent';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { GritDocument } from '../../types';
import { serviceDeskApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles({
  header: {
    color: 'black',
    backgroundImage: 'linear-gradient(to bottom right, pink, yellow)',
  },
});

export const ServiceDeskDocumentComponent = () => (
  <Page themeId="tool">
    <CommonHeaderComponent></CommonHeaderComponent>
    <Content>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/servicedesk">
          Services Desk
        </Link>
        <Typography color="textPrimary">Documents</Typography>
      </Breadcrumbs>
      <GritDocumentList></GritDocumentList>
    </Content>
  </Page>
);


////////////////////////////

export const GritDocumentList = () => {
  const [documents, setDocuments] = useState<GritDocument[]>([]);
  const classes = useStyles();
  const serviceDeskApi = useApi(serviceDeskApiRef);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {

    const response = await serviceDeskApi.getAllDocument()
    setDocuments(response);
  };

  return (
    <>
      <Typography paragraph>
        {/* The most basic setup is to place a bunch of cards into a large grid,
      leaving styling to the defaults. Try to resize the window to see how they
      rearrange themselves to fit the viewport. */}
      </Typography>
      <ItemCardGrid>
        {documents.map(index => (
          <Card key={index.name}>
            <CardMedia>
              <ItemCardHeader title={index.name} subtitle="" classes={{ root: classes.header }}/>
            </CardMedia>
            <CardContent>
              {index.description}
            </CardContent>
            <CardActions>
              <LinkButton color="primary" to={index.url}>
                Go There!
              </LinkButton>
            </CardActions>
          </Card>
        ))}
      </ItemCardGrid>
    </>
  );
};

