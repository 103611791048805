import React, { useEffect, useState } from 'react';
import {
  Page,
  Content,
  Breadcrumbs,
} from '@backstage/core-components';

import { CommonHeaderComponent } from '../CommonHeaderComponent';
import { VpnRequestHistoryComponent } from '../VpnRequestHistoryComponent';
import { VpnRequestCreateComponent } from '../VpnRequestCreateComponent';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { VpnRequest } from '../../types';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { serviceDeskApiRef } from '../../api';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export const VpnComponent = () => {
  const identityApi = useApi(identityApiRef);
  const serviceDeskApi = useApi(serviceDeskApiRef);

  const [requests, setRequests] = useState<VpnRequest[]>([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const info = await identityApi.getProfileInfo();

    const response = await serviceDeskApi.getVpnRequestsByUser({
      email: info?.email,
    });
    setRequests(response);
  };


  const handleSucessRequest = async (request: VpnRequest) => {
    fetchUsers();
  };

  return (
    <>
      <Page themeId="tool">
        <CommonHeaderComponent></CommonHeaderComponent>
        <Content>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/servicedesk">
              Services Desk
            </Link>
            <Typography color="textPrimary">Request VPN to the office</Typography>
          </Breadcrumbs>
          <br></br>
          <VpnRequestCreateComponent onSuccessRequest={handleSucessRequest} ></VpnRequestCreateComponent>
          <br></br>
          <VpnRequestHistoryComponent requests={requests}></VpnRequestHistoryComponent>
        </Content>
      </Page>
    </>

  );
}