import {
  Environment,
  PriorityLevel,
  Release,
  ReleaseEnvironment,
  ReleaseIssue,
  ReleaseRole,
  ReleaseRollbackStep,
  ReleaseStatus,
  ReleaseStep,
  ReleaseStepStatus,
  RiskLevel,
} from './types';

/** @public */
export type DbReleasesRow = {
  id: string;
  code: string;
  name: string;
  description: string;
  risk_level: RiskLevel;
  priority_level: PriorityLevel;
  status: ReleaseStatus;
  entity_ref: string;
  service_refs: string;
  created_at: Date;
  updated_at: Date;
  ready_at?: Date;
  environments?: DbReleaseEnvironmentsRow[];
  roles?: DbReleaseRolesRow[];
  steps?: DbReleaseStepsRow[];
  issues?: DbReleaseIssuesRow[];
  rollback_steps?: DbReleaseRollbackStepsRow[];
  jira_version_link?: string;
  completed_at?: Date;
  created_by?: string;
  main_pic?: string;
};

/** @public */
export type DbReleaseStepsRow = {
  release_id: string;
  id: string;
  env: Environment;
  order_number: number;
  summary: string;
  start_at?: Date;
  end_at?: Date;
  role: string;
  status: ReleaseStepStatus;
};

// /** @public */
// export type DbReleaseMembersRow = {
//   release_id: string;
//   identity_ref: string;
//   role: string;
//   ordering: Ordering;
// };

/** @public */
export type DbReleaseRolesRow = {
  id: string;
  release_id: string;
  name: string;
  primary_members: string;
  secondary_members: string;
  created_at: Date;
};

/** @public */
export type DbReleaseEnvironmentsRow = {
  id: string;
  release_id: string;
  env: Environment;
  start_at: Date;
  end_at: Date;
  created_at: Date;
};

/** @public */
export type DbReleaseIssuesRow = {
  release_id: string;
  id: string;
  env: Environment;
  description: string;
  cause: string;
  solution: string;
  happened_at: Date;
  identity_ref: string;
  time_lost: number;
};

/** @public */
export type DbReleaseRollbackStepsRow = {
  release_id: string;
  id: string;
  order_number: number;
  description: string;
};

/** @public */
export function buildDbReleaseRow(release: Release): DbReleasesRow {
  return {
    id: release.id,
    status: release.status,
    code: release.code,
    description: release.description,
    name: release.name,
    priority_level: release.priorityLevel,
    risk_level: release.riskLevel,
    entity_ref: release.entityRef,
    service_refs: release.serviceRefs ? release.serviceRefs.join(',') : '',
    created_at: release.createdAt,
    updated_at: release.updatedAt,
    ready_at: release.readyAt,
    jira_version_link: release.jiraVersionLink,
    environments: release.environments
      ? release.environments?.map(tmp => buildDbReleaseEnvironment(tmp))
      : [],
    roles: release.roles
      ? release.roles?.map(tmp => buildDbReleaseRole(tmp))
      : [],
    steps: release.steps
      ? release.steps?.map(tmp => buildDbReleaseStep(tmp))
      : [],
    rollback_steps: release.rollbackSteps
      ? release.rollbackSteps?.map(tmp => buildDbReleaseRollbackStep(tmp))
      : [],
    issues: release.issues
      ? release.issues?.map(tmp => buildDbReleaseIssue(tmp))
      : [],
    completed_at: release.completedAt,
    created_by: release.createdBy,
    main_pic: release.mainPIC,
  };
}

/** @public */
export function parseDbReleaseRow(releaseRow: DbReleasesRow): Release {
  return {
    id: releaseRow.id,
    status: releaseRow.status,
    code: releaseRow.code,
    description: releaseRow.description,
    name: releaseRow.name,
    priorityLevel: releaseRow.priority_level,
    riskLevel: releaseRow.risk_level,
    entityRef: releaseRow.entity_ref,
    serviceRefs: releaseRow.service_refs.split(','),
    createdAt: releaseRow.created_at,
    updatedAt: releaseRow.updated_at,
    readyAt: releaseRow.ready_at,
    environments: releaseRow.environments?.map(obj => {
      return parseDbReleaseEnvironment(obj);
    }),
    issues: releaseRow.issues?.map(obj => {
      return parseDbReleaseIssue(obj);
    }),
    steps: releaseRow.steps?.map(obj => {
      return parseDbReleaseStep(obj);
    }),
    roles: releaseRow.roles?.map(obj => {
      return parseDbReleaseRole(obj);
    }),
    rollbackSteps: releaseRow.rollback_steps?.map(obj => {
      return parseDbReleaseRollbackStep(obj);
    }),
    jiraVersionLink: releaseRow.jira_version_link,
    completedAt: releaseRow.completed_at,
    createdBy: releaseRow.created_by,
    mainPIC: releaseRow.main_pic,
  };
}

/** @public */
export function buildDbReleaseStep(
  releaseStep: ReleaseStep,
): DbReleaseStepsRow {
  return {
    id: releaseStep.id,
    env: releaseStep.env,
    summary: releaseStep.summary,
    release_id: releaseStep.releaseID,
    start_at: releaseStep.startAt,
    end_at: releaseStep.endAt,
    order_number: releaseStep.orderNumber,
    role: releaseStep.role,
    status: releaseStep.status,
  };
}

/** @public */
export function parseDbReleaseStep(
  releaseStepRow: DbReleaseStepsRow,
): ReleaseStep {
  return {
    id: releaseStepRow.id,
    env: releaseStepRow.env,
    summary: releaseStepRow.summary,
    releaseID: releaseStepRow.release_id,
    startAt: releaseStepRow.start_at
      ? new Date(releaseStepRow.start_at)
      : undefined,
    endAt: releaseStepRow.end_at ? new Date(releaseStepRow.end_at) : undefined,
    orderNumber: releaseStepRow.order_number,
    role: releaseStepRow.role,
    status: releaseStepRow.status,
  };
}

/** @public */
export function buildDbReleaseIssue(
  releaseIssue: ReleaseIssue,
): DbReleaseIssuesRow {
  return {
    id: releaseIssue.id,
    cause: releaseIssue.cause,
    env: releaseIssue.env,
    solution: releaseIssue.solution,
    description: releaseIssue.description,
    release_id: releaseIssue.releaseID,
    happened_at: releaseIssue.happenedAt,
    identity_ref: releaseIssue.identityRef,
    time_lost: releaseIssue.timeLost,
  };
}

/** @public */
export function parseDbReleaseIssue(
  releaseIssueRow: DbReleaseIssuesRow,
): ReleaseIssue {
  return {
    id: releaseIssueRow.id,
    cause: releaseIssueRow.cause,
    env: releaseIssueRow.env,
    solution: releaseIssueRow.solution,
    description: releaseIssueRow.description,
    releaseID: releaseIssueRow.release_id,
    happenedAt: releaseIssueRow.happened_at,
    identityRef: releaseIssueRow.identity_ref,
    timeLost: releaseIssueRow.time_lost,
  };
}

/** @public */
export function buildDbReleaseEnvironment(
  releaseEnv: ReleaseEnvironment,
): DbReleaseEnvironmentsRow {
  return {
    id: releaseEnv.id,
    env: releaseEnv.env,
    release_id: releaseEnv.releaseID,
    start_at: releaseEnv.startAt,
    end_at: releaseEnv.endAt,
    created_at: releaseEnv.createdAt,
  };
}

/** @public */
export function parseDbReleaseEnvironment(
  releaseEnvRow: DbReleaseEnvironmentsRow,
): ReleaseEnvironment {
  return {
    id: releaseEnvRow.id,
    env: releaseEnvRow.env,
    releaseID: releaseEnvRow.release_id,
    startAt: releaseEnvRow.start_at,
    endAt: releaseEnvRow.end_at,
    createdAt: releaseEnvRow.created_at,
  };
}

/** @public */
export function buildDbReleaseRole(
  releaseRole: ReleaseRole,
): DbReleaseRolesRow {
  return {
    id: releaseRole.id,
    release_id: releaseRole.releaseID,
    name: releaseRole.name,
    primary_members: releaseRole.primaryMembers.join(','),
    secondary_members: releaseRole.secondaryMembers.join(','),
    created_at: releaseRole.createdAt,
  };
}

/** @public */
export function parseDbReleaseRole(
  releaseRoleRow: DbReleaseRolesRow,
): ReleaseRole {
  return {
    id: releaseRoleRow.id,
    releaseID: releaseRoleRow.release_id,
    name: releaseRoleRow.name,
    primaryMembers:
      releaseRoleRow.primary_members.length === 0
        ? []
        : releaseRoleRow.primary_members.split(','),
    secondaryMembers:
      releaseRoleRow.secondary_members.length === 0
        ? []
        : releaseRoleRow.secondary_members.split(','),
    createdAt: releaseRoleRow.created_at,
  };
}

/** @public */
export function buildDbReleaseRollbackStep(
  releaseRollbackStep: ReleaseRollbackStep,
): DbReleaseRollbackStepsRow {
  return {
    release_id: releaseRollbackStep.releaseID,
    id: releaseRollbackStep.id,
    order_number: releaseRollbackStep.orderNumber,
    description: releaseRollbackStep.description,
  };
}

/** @public */
export function parseDbReleaseRollbackStep(
  releaseRollbackStepRow: DbReleaseRollbackStepsRow,
): ReleaseRollbackStep {
  return {
    releaseID: releaseRollbackStepRow.release_id,
    id: releaseRollbackStepRow.id,
    orderNumber: releaseRollbackStepRow.order_number,
    description: releaseRollbackStepRow.description,
  };
}

// /** @public */
// export function buildDbReleaseMember(
//   releaseMember: ReleaseMember,
// ): DbReleaseMembersRow {
//   return {
//     release_id: releaseMember.releaseID,
//     role: releaseMember.role,
//     ordering: releaseMember.ordering,
//     identity_ref: releaseMember.identityRef,
//   };
// }

// /** @public */
// export function parseDbReleaseMember(
//   releaseMemberRow: DbReleaseMembersRow,
// ): ReleaseMember {
//   return {
//     releaseID: releaseMemberRow.release_id,
//     role: releaseMemberRow.role,
//     ordering: releaseMemberRow.ordering,
//     identityRef: releaseMemberRow.identity_ref,
//   };
// }
