import React from 'react';
import {
  Header
} from '@backstage/core-components';

export const CommonHeaderComponent = () => (
  <Header title="Grit Internal Services Desk Center" subtitle="Welcome Gritter! You can raise a service desk request from the options provided.">

  </Header>
);
