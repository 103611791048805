/** @public */
export enum Environment {
  Production = 'production',
  Sandbox = 'sandbox',
  Staging = 'staging',
  Develop = 'develop',
}

/** @public */

export enum PriorityLevel {
  Critical = 4,
  High = 3,
  Medium = 2,
  Low = 1,
}

/** @public */
export enum RiskLevel {
  High = 3,
  Medium = 2,
  Low = 1,
}

/** @public */
export enum ReleaseStepStatus {
  Pending = 0,
  Ready = 1,
  Processing = 2,
  Completed = 3,
  Failed = 4,
  Archived = 6,
}

/** @public */
export enum ReleaseStatus {
  Pending = 1,
  Ready = 2,
  Processing = 3,
  Completed = 4,
  Failed = 5,
}

/** @public */
export enum Ordering {
  Primary = 1,
  Secondary = 2,
}

/** @public */
export type Release = {
  id: string;
  code: string;
  name: string;
  description: string;
  priorityLevel: PriorityLevel;
  riskLevel: RiskLevel;
  status: ReleaseStatus;
  entityRef: string;
  serviceRefs: Array<string>;
  updatedAt: Date;
  createdAt: Date;
  readyAt?: Date;
  roles?: Array<ReleaseRole>;
  environments?: Array<ReleaseEnvironment>;
  steps?: Array<ReleaseStep>;
  issues?: Array<ReleaseIssue>;
  rollbackSteps?: Array<ReleaseRollbackStep>;
  jiraVersionLink?: string;
  completedAt?: Date;
  createdBy?: string;
  mainPIC?: string;
};

/** @public */
export type ReleaseEnvironment = {
  id: string;
  releaseID: string;
  env: Environment;
  startAt: Date;
  endAt: Date;
  createdAt: Date;
};

/** @public */
export type ReleaseStep = {
  releaseID: string;
  id: string;
  env: Environment;
  startAt?: Date;
  endAt?: Date;
  summary: string;
  orderNumber: number;
  role: string;
  status: ReleaseStepStatus;
};

// /** @public */
// export type ReleaseMember = {
//   releaseID: string;
//   role: string;
//   ordering: number;
//   identityRef: string;
// };

/** @public */
export type ReleaseRole = {
  id: string;
  releaseID: string;
  name: string;
  primaryMembers: string[];
  secondaryMembers: string[];
  createdAt: Date;
};

/** @public */
export type ReleaseIssue = {
  releaseID: string;
  id: string;
  env: Environment;
  happenedAt: Date;
  cause: string;
  solution: string;
  description: string;
  identityRef: string;
  timeLost: number;
};

/** @public */
export type ReleaseRollbackStep = {
  releaseID: string;
  id: string;
  orderNumber: number;
  description: string;
};

/** @public */
export enum ReleaseUpdateAction {
  Create,
  Update,
  Upsert,
  Delete,
}

/** @public */
export enum ReleaseUpdateActionTest {
  Create,
  Update,
  Upsert,
  Delete,
}
