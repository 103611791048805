import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Swal from 'sweetalert2';
import { useAsync } from 'react-use';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { serviceDeskApiRef } from '../../api';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { VpnRequest } from '../../types';

interface RequestProps {
  onSuccessRequest: (request: VpnRequest) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const VpnRequestCreateComponent: React.FC<RequestProps> = ({ onSuccessRequest }) => {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const serviceDeskApi = useApi(serviceDeskApiRef);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const res = useAsync(async () => {
    const info = await identityApi.getProfileInfo();
    const response = await serviceDeskApi.getCetificateByUser({
      email: info?.email,
    });
    return response;
  }, []);
  const userCertificates = res.value?.reduce((result: any, { id, name }) => {
    result[id] = name;
    return result;
  }, {});

  useEffect(() => { }, [isConfirmed]);

  const handleCreateClick = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      text: "Please confirm that you are requesting VPN account. Once the request is submitted, it can't be reverted.",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!',
      timer: 10000,
      timerProgressBar: true,
    });
    if (isConfirmed) {
      setLoading(true);
      try {
        const info = await identityApi.getProfileInfo();
        const response = await serviceDeskApi.createVpnRequest({
          action: 'Create',
          email: info?.email,
          cert_id: null,
        });
        Swal.fire(
          'Request Successfull!',
          'Your request has been submitted. You can track its progress on the Request History page',
          'success',
        );
        onSuccessRequest(response);

      } catch (error) {
        console.log({ error })
        setError(undefined)
      } finally {
        setLoading(false);
      }
    }
  };

  const handleExtendClick = async () => {
    const { value: certificateId } = await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      text: "Please confirm that you are extending VPN certificate expiration date. Once the request is submitted, it can't be reverted.",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!',
      timer: 20000,
      timerProgressBar: true,
      input: 'select',
      inputOptions: userCertificates,
      inputPlaceholder: 'Select your certificate',
      inputValidator: value => {
        return new Promise(resolve => {
          if (value === null || value === undefined || value === '') {
            resolve('You need to select a certificate =))');
          } else {
            resolve();
          }
        });
      },
    });

    if (certificateId) {
      try {
        const info = await identityApi.getProfileInfo();
        const response = await serviceDeskApi.createVpnRequest({
          action: 'Extend',
          email: info?.email,
          cert_id: certificateId,
        });
        Swal.fire(
          'Request Successfull!',
          'Your request has been submitted. You can track its progress on the Request History page',
          'success',
        );
        onSuccessRequest(response);
      } catch (error) {
        console.log({ error })
        setError(undefined)
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Choose between 2 options
        </Typography>
        <Typography variant="body1" component="p">
          <br></br>
          1. Create new VPN account (In case you don't have VPN accounts before)
        </Typography>
        <Typography className={classes.pos} color="primary">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateClick}
            className={classes.button}
            startIcon={<AddIcon />}
          >
            Create
          </Button>
        </Typography>
        <Typography variant="body1" component="p">
          <br></br>
          2. Extend VPN certificate (In case you want to extend VPN certificate
          expiration date)
        </Typography>
        <Typography className={classes.pos} color="primary">
          <Button
            variant="contained"
            color="primary"
            onClick={handleExtendClick}
            className={classes.button}
            startIcon={<HourglassEmptyIcon />}
          >
            Extend
          </Button>
        </Typography>
        <Typography variant="body1" component="p">
          <br></br>
          Note: <br></br>- Please notice that request after office hour will not
          be support as fast as in office hour (Office hour:{' '}
          <b>10:00 - 18:00</b>). <br></br>- After submitting the request, the
          administrator will review and process the request you provided. You
          can check the status of your request in the <b>Request History</b>{' '}
          table below. <br></br>- When the request is approved, we will send an{' '}
          <b> Invitation to join Grit Private Network</b> to your email. Please
          check your mailbox and follow the instructions included in the mail.
        </Typography>
      </CardContent>
    </Card>
  );
}
