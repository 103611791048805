/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  Release,
  ReleaseEnvironment,
  ReleaseIssue,
  ReleaseRole,
  ReleaseRollbackStep,
  ReleaseStatus,
  ReleaseStep,
} from '@internal/plugin-release-common';

/**
 * @public
 */
export const releaseApiRef = createApiRef<ReleaseApi>({
  id: 'plugin.release.service',
});

export type ListReleasesPagination = {
  limit?: number;
  offset?: number;
  after?: string;
};

/**
 * @public
 */
export type GetListReleasesRequest = {
  /**
   * If multiple filter sets are given as an array, then there is effectively an
   * OR between each filter set.
   *
   * Within one filter set, there is effectively an AND between the various
   * keys.
   *
   * Within one key, if there are more than one value, then there is effectively
   * an OR between them.
   */
  filter?: Record<string, string | string[] | number | null>;
  // | Record<string, string | string[] | null>[]

  // If true, will filter results that satisfies the release.list.update permission
  editable?: boolean;
  pagination?: ListReleasesPagination;
  orderBy?: {
    field: string;
    direction: 'asc' | 'desc';
  };
};

export type GetListReleasesResponse = {
  releases: Release[];
  total: number;
};

/**
 * @public
 */
export interface ReleaseApi {
  getListReleases(req: GetListReleasesRequest): Promise<GetListReleasesResponse>;
  createRelease(release: Release): Promise<Release>;
  getRelease(releaseId: string): Promise<Release>;
  updateRelease(release: Release): Promise<Release>;
  updateReleaseStatus(
    releaseId: string,
    status: ReleaseStatus,
  ): Promise<Release>;
  upsertRelease(release: Release): Promise<Release>;
  deleteRelease(releaseId: string): Promise<void>;
  cloneRelease(release: Pick<Release, 'id' | 'createdBy'>): Promise<Release>;

  getListReleaseSteps(releaseID: string): Promise<ReleaseStep[]>;
  createReleaseStep(releaseStep: ReleaseStep): Promise<ReleaseStep>;
  updateReleaseStep(releaseStep: ReleaseStep): Promise<ReleaseStep>;
  deleteReleaseStep(releaseStep: ReleaseStep): Promise<void>;
  batchUpdateReleaseSteps(releaseSteps: ReleaseStep[]): Promise<ReleaseStep[]>;

  getListReleaseEnvironments(releaseID: string): Promise<ReleaseEnvironment[]>;
  createReleaseEnvironment(
    releaseEnv: ReleaseEnvironment,
  ): Promise<ReleaseEnvironment>;
  updateReleaseEnvironment(
    releaseEnv: ReleaseEnvironment,
  ): Promise<ReleaseEnvironment>;
  deleteReleaseEnvironment(releaseEnv: ReleaseEnvironment): Promise<void>;

  getListReleaseRoles(releaseID: string): Promise<ReleaseRole[]>;
  createReleaseRole(releaseRole: ReleaseRole): Promise<ReleaseRole>;
  updateReleaseRole(releaseRole: ReleaseRole): Promise<ReleaseRole>;
  deleteReleaseRole(releaseRole: ReleaseRole): Promise<void>;

  getListReleaseIssues(releaseID: string): Promise<ReleaseIssue[]>;
  createReleaseIssue(releaseIssue: ReleaseIssue): Promise<ReleaseIssue>;
  updateReleaseIssue(releaseIssue: ReleaseIssue): Promise<ReleaseIssue>;
  deleteReleaseIssue(releaseIssue: ReleaseIssue): Promise<void>;
  deleteAllReleaseIssues(releaseID: string): Promise<void>;
  batchUpdateReleaseIssues(
    releaseIssues: ReleaseIssue[],
  ): Promise<ReleaseIssue[]>;

  getListReleaseRollbackSteps(
    releaseID: string,
  ): Promise<ReleaseRollbackStep[]>;
  createReleaseRollbackStep(
    releaseRollbackStep: ReleaseRollbackStep,
  ): Promise<ReleaseRollbackStep>;
  updateReleaseRollbackStep(
    releaseRollbackStep: ReleaseRollbackStep,
  ): Promise<ReleaseRollbackStep>;
  deleteReleaseRollbackStep(
    releaseRollbackStep: ReleaseRollbackStep,
  ): Promise<void>;
  batchUpdateReleaseRollbackSteps(
    releaseRollbackSteps: ReleaseRollbackStep[],
  ): Promise<ReleaseRollbackStep[]>;
}
