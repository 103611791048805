/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// import { Entity } from '@backstage/catalog-model';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  DbReleasesRow,
  Release,
  buildDbReleaseRow,
  parseDbReleaseRow,
  ReleaseEnvironment,
  buildDbReleaseEnvironment,
  ReleaseStep,
  buildDbReleaseStep,
  parseDbReleaseEnvironment,
  parseDbReleaseStep,
  ReleaseIssue,
  buildDbReleaseIssue,
  parseDbReleaseIssue,
  ReleaseRollbackStep,
  buildDbReleaseRollbackStep,
  parseDbReleaseRollbackStep,
  DbReleaseEnvironmentsRow,
  DbReleaseStepsRow,
  DbReleaseIssuesRow,
  DbReleaseRollbackStepsRow,
  ReleaseRole,
  parseDbReleaseRole,
  DbReleaseRolesRow,
  buildDbReleaseRole,
  ReleaseStatus,
} from '@internal/plugin-release-common';

import {
  GetListReleasesRequest,
  GetListReleasesResponse,
  ReleaseApi,
} from './ReleaseApi';

/**
 * @public
 */
export class ReleaseClient implements ReleaseApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getListReleases(
    req: GetListReleasesRequest = {},
  ): Promise<GetListReleasesResponse> {
    const { filter = [], editable } = req;
    const params: string[] = [];

    // console.log('filter', filter)

    // the "outer array" defined by `filter` occurrences corresponds to "anyOf" filters
    // the "inner array" defined within a `filter` param corresponds to "allOf" filters
    for (const filterItem of [filter].flat()) {
      const filterParts: string[] = [];
      for (const [key, value] of Object.entries(filterItem)) {
        for (const v of [value].flat()) {
          if (typeof v === 'string' || typeof v === 'number') {
            filterParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(v)}`,
            );
          }
        }
      }

      if (filterParts.length) {
        params.push(`filter=${filterParts.join(',')}`);
      }
    }

    // console.log('params', params)

    if (editable) {
      params.push('editable=true');
    }

    if (req.orderBy) {
      params.push(`orderBy=${req.orderBy.field} ${req.orderBy.direction}`);
    }

    if (req.pagination) {
      if (req.pagination.offset) {
        params.push(`offset=${req.pagination.offset}`);
      }
      if (req.pagination.limit) {
        params.push(`limit=${req.pagination.limit}`);
      }
    }

    const query = params.length ? `?${params.join('&')}` : '';
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${query}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const resRaw = await resp.json();
    // const releasesRaw = resRaw.releases;
    return {
      releases: resRaw.releases.map((tmp: DbReleasesRow) =>
        parseDbReleaseRow(tmp),
      ),
      total: resRaw.total,
    };
  }

  async createRelease(release: Release): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(buildDbReleaseRow(release)),
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw);
  }

  async getRelease(releaseId: string): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw);
  }

  async updateRelease(release: Release): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${release.id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(buildDbReleaseRow(release)),
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw);
  }

  async updateReleaseStatus(
    releaseId: string,
    status: ReleaseStatus,
  ): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify({
        id: releaseId,
        status: status,
        completed_at:
          status === ReleaseStatus.Completed ? new Date() : undefined,
      }),
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    // return;
    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw[0]);
  }

  async upsertRelease(release: Release): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${release.id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(buildDbReleaseRow(release)),
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw);
    // return release;
  }

  async deleteRelease(releaseId: string) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}`, {
      method: 'DELETE',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async cloneRelease(
    release: Pick<Release, 'id' | 'createdBy'>,
  ): Promise<Release> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${release.id}/clone`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ id: release.id, created_by: release.createdBy }),
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    const releaseRaw = await resp.json();
    return parseDbReleaseRow(releaseRaw);
  }

  /// -------------------env -------------------------
  async getListReleaseEnvironments(
    releaseId: string,
  ): Promise<ReleaseEnvironment[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}/envs`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseEnvsRaw: DbReleaseEnvironmentsRow[] = await resp.json();
    return releaseEnvsRaw.map(tmp => parseDbReleaseEnvironment(tmp));
  }

  async createReleaseEnvironment(
    releaseEnv: ReleaseEnvironment,
  ): Promise<ReleaseEnvironment> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseEnv.releaseID}/envs`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(buildDbReleaseEnvironment(releaseEnv)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseEnvRaw = await resp.json();
    return parseDbReleaseEnvironment(releaseEnvRaw);
  }

  async updateReleaseEnvironment(
    releaseEnv: ReleaseEnvironment,
  ): Promise<ReleaseEnvironment> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseEnv.releaseID}/envs/${releaseEnv.id}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(buildDbReleaseEnvironment(releaseEnv)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseEnvRaw = await resp.json();
    return parseDbReleaseEnvironment(releaseEnvRaw);
  }

  async deleteReleaseEnvironment(releaseEnv: ReleaseEnvironment) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseEnv.releaseID}/envs/${releaseEnv.id}`,
      {
        method: 'DELETE',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  // --------------- steps ----------------
  async getListReleaseSteps(releaseId: string): Promise<ReleaseStep[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}/steps`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseStepsRaw: DbReleaseStepsRow[] = await resp.json();
    return releaseStepsRaw.map(tmp => parseDbReleaseStep(tmp));
  }

  async createReleaseStep(releaseStep: ReleaseStep): Promise<ReleaseStep> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseStep.releaseID}/steps`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(buildDbReleaseStep(releaseStep)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseStepRaw = await resp.json();
    return parseDbReleaseStep(releaseStepRaw);
  }

  async updateReleaseStep(releaseStep: ReleaseStep): Promise<ReleaseStep> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    console.log('asdfasdfsdfasd', releaseStep);
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseStep.releaseID}/steps/${releaseStep.id}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(buildDbReleaseStep(releaseStep)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseStepRaw = await resp.json();
    return parseDbReleaseStep(releaseStepRaw);
  }

  async deleteReleaseStep(releaseStep: ReleaseStep) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseStep.releaseID}/steps/${releaseStep.id}`,
      {
        method: 'DELETE',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async batchUpdateReleaseSteps(
    releaseSteps: ReleaseStep[],
  ): Promise<ReleaseStep[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseSteps[0].releaseID}/steps:batchUpdate`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(releaseSteps.map(tmp => buildDbReleaseStep(tmp))),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseStepsRaw: DbReleaseStepsRow[] = await resp.json();
    return releaseStepsRaw.map(tmp => parseDbReleaseStep(tmp));
  }

  // -------- issue ------------
  async getListReleaseIssues(releaseId: string): Promise<ReleaseIssue[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}/issues`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseIssuesRaw: DbReleaseIssuesRow[] = await resp.json();
    return releaseIssuesRaw.map(tmp => parseDbReleaseIssue(tmp));
  }

  async createReleaseIssue(releaseIssue: ReleaseIssue): Promise<ReleaseIssue> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseIssue.releaseID}/issues`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(buildDbReleaseIssue(releaseIssue)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseIssueRaw = await resp.json();
    return parseDbReleaseIssue(releaseIssueRaw);
  }

  async updateReleaseIssue(releaseIssue: ReleaseIssue): Promise<ReleaseIssue> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseIssue.releaseID}/issues/${releaseIssue.id}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(buildDbReleaseIssue(releaseIssue)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseIssueRaw = await resp.json();
    return parseDbReleaseIssue(releaseIssueRaw);
  }

  async batchUpdateReleaseIssues(
    releaseIssues: ReleaseIssue[],
  ): Promise<ReleaseIssue[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseIssues[0].releaseID}/issues:batchUpdate`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(
          releaseIssues.map(tmp => buildDbReleaseIssue(tmp)),
        ),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseIssuesRaw: DbReleaseIssuesRow[] = await resp.json();
    return releaseIssuesRaw.map(tmp => parseDbReleaseIssue(tmp));
  }

  async deleteReleaseIssue(releaseIssue: ReleaseIssue) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseIssue.releaseID}/issues/${releaseIssue.id}`,
      {
        method: 'DELETE',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async deleteAllReleaseIssues(releaseID: string) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseID}/issues`, {
      method: 'DELETE',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  // --------- rollback step ---------------
  async getListReleaseRollbackSteps(
    releaseId: string,
  ): Promise<ReleaseRollbackStep[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseId}/rollbackSteps`,
      {
        method: 'GET',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRollbackStepsRaw: DbReleaseRollbackStepsRow[] =
      await resp.json();
    return releaseRollbackStepsRaw.map(tmp => parseDbReleaseRollbackStep(tmp));
  }

  async createReleaseRollbackStep(
    releaseRollbackStep: ReleaseRollbackStep,
  ): Promise<ReleaseRollbackStep> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRollbackStep.releaseID}/rollbackSteps`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(buildDbReleaseRollbackStep(releaseRollbackStep)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRollbackStepRaw = await resp.json();
    return parseDbReleaseRollbackStep(releaseRollbackStepRaw);
  }

  async updateReleaseRollbackStep(
    releaseRollbackStep: ReleaseRollbackStep,
  ): Promise<ReleaseRollbackStep> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRollbackStep.releaseID}/rollbackSteps/${releaseRollbackStep.id}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(buildDbReleaseRollbackStep(releaseRollbackStep)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRollbackStepRaw = await resp.json();
    return parseDbReleaseRollbackStep(releaseRollbackStepRaw);
  }

  async deleteReleaseRollbackStep(releaseRollbackStep: ReleaseRollbackStep) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRollbackStep.releaseID}/rollbackSteps/${releaseRollbackStep.id}`,
      {
        method: 'DELETE',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }

  async batchUpdateReleaseRollbackSteps(
    releaseRollbackSteps: ReleaseRollbackStep[],
  ): Promise<ReleaseRollbackStep[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRollbackSteps[0].releaseID}/rollbackSteps:batchUpdate`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(
          releaseRollbackSteps.map(tmp => buildDbReleaseRollbackStep(tmp)),
        ),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRollbackStepsRaw: DbReleaseRollbackStepsRow[] =
      await resp.json();
    return releaseRollbackStepsRaw.map(tmp => parseDbReleaseRollbackStep(tmp));
  }

  // --------------- members ----------------
  async getListReleaseRoles(releaseId: string): Promise<ReleaseRole[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(`${baseUrl}/${releaseId}/roles`, {
      method: 'GET',
    });

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRolesRaw: DbReleaseRolesRow[] = await resp.json();
    return releaseRolesRaw.map(tmp => parseDbReleaseRole(tmp));
  }

  async createReleaseRole(releaseRole: ReleaseRole): Promise<ReleaseRole> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRole.releaseID}/roles`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(buildDbReleaseRole(releaseRole)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRoleRaw: DbReleaseRolesRow = await resp.json();
    return parseDbReleaseRole(releaseRoleRaw);
  }

  async updateReleaseRole(releaseRole: ReleaseRole): Promise<ReleaseRole> {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRole.releaseID}/roles/${releaseRole.id}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(buildDbReleaseRole(releaseRole)),
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    const releaseRoleRaw = await resp.json();
    return parseDbReleaseRole(releaseRoleRaw);
  }

  async deleteReleaseRole(releaseRole: ReleaseRole) {
    const baseUrl = await this.discoveryApi.getBaseUrl('release');
    const resp = await this.fetchApi.fetch(
      `${baseUrl}/${releaseRole.releaseID}/roles/${releaseRole.id}`,
      {
        method: 'DELETE',
      },
    );

    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
  }
}
